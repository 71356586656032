import { Box,OrbitControls, Html, useCursor,useTexture, MeshReflectorMaterial, Image, Text, Environment } from '@react-three/drei'
import { Canvas,useLoader, useFrame } from '@react-three/fiber'
import { Controllers, useHitTest, VRButton, XR, ARButton,toggleSession } from '@react-three/xr'
import React, { ComponentProps, useEffect, useState ,useRef} from 'react'

import { useRoute, useLocation } from 'wouter'
import { easing } from 'maath'
import getUuid from 'uuid-by-string'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'


import {
  DoubleSide,MeshBasicMaterial,LessDepth,
  Raycaster,
  Vector2, Vector3, Mesh,Quaternion, Color
} from "three";

import Dashboard from './Dashboard'
import Image360 from './Image360'
import ChatRoom from './ChatRoom'

//import Html5QrcodePlugin from './Html5QrcodePlugin.jsx';
//import ResultContainerPlugin from './ResultContainerPlugin.jsx';


const GOLDENRATIO = 1.61803398875
/*
const artId="art1"
const pexel = (id) => `https://images.pexels.com/photos/${id}/pexels-photo-${id}.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260`
*/

const imagesPosition = [
  // Front
  { position: [0, 0, 1.5], rotation: [0, 0, 0] },

  // Left
  { position: [-1.15, 0, 1.5], rotation: [0, Math.PI / 2.5, 0] },
  { position: [1.15, 0, 1.5], rotation: [0, -Math.PI / 2.5, 0] },
  // Right
  { position: [2, 0, 0.25], rotation: [0, -Math.PI / 2.5, 0] },
  { position: [-2, 0, 2.75], rotation: [0, Math.PI / 2.5, 0] },
  { position: [2.5, 0, 2.75], rotation: [0, -Math.PI / 2.5, 0] },
  // Back
  { position: [-0.8, 0, -0.6], rotation: [0, 0, 0] },
  { position: [0.8, 0, -0.6], rotation: [0, 0, 0] }
]




function Infos() {
  return (
      <div style={{ maxWidth: '50vw', margin: 'auto', backgroundColor: 'white' }}>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/q69Vu67q_TY?si=od1v1iQfO7QsAnPf" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/Kum81xOECUA?si=EOpqODPqxc4juiFr" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>  
      <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FHasseneALAYA82%2Fposts%2Fpfbid07q8dypfqsBM2vJnbDyffVtt2Wabh9Vj4TnA2mh5HxuENnBCTiXYknEJyqWFx7rozl&show_text=true&width=500&is_preview=true" width="500" height="686" style={{border:"none",overflow:"hidden"}}  frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
      <iframe src="https://www.facebook.com/plugins/video.php?height=252&href=https%3A%2F%2Fwww.facebook.com%2FHasseneALAYA82%2Fvideos%2F756663746346026%2F&show_text=true&width=560&t=0" width="560" height="367" style={{border:"none",overflow:"hidden"}} frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
      </div>
  );
}


class SceneX extends React.Component {

  constructor(props) {
      super(props);

      this.state = {
          dataScenes:null,
          config: [{"sceneId":"artview_0", "cubeTexture":'[   "content/artview_0/r",     "content/artview_0/l",     "content/artview_0/u",     "content/artview_0/d",     "content/artview_0/f",     "content/artview_0/b" ]', "infoPoint":[ {"id":"1", "name":"secne1", "infopoint_id":"img2info_01", "position":[-10, 10, 26.11] }, {"id":"1", "name":"secne1", "infopoint_id":"img2info_01", "position":[20, 10, 26.11] }], "transitionPoint":[ {"id":"1", "name":"secne1", "sceneId":{"sceneId":"artview_0"}, "position":[25, 1, 42.11] },  {"id":"2", "name":"secne1test", "sceneId":{"sceneId":"viewpoint_1"}, "position":[-77, 2, 18.11] } ]} ]
      }

      this.loadAllScene()
  }


  loadAllScene = async (event) => {
    //event.preventDefault();
  
    //fetch to config
    const formData = new FormData();
    formData.append("action", "sql"); 
    formData.append("sql_request", "SELECT DISTINCT * FROM elmahdiascene;");
  
    await fetch('/dataAction.php'+"?date="+Date.now(), {
      method: 'POST',
      cache: 'no-cache',
      headers: {
  //	   'Content-Type': 'multipart/form-data',
      },
      body: formData
  
        //  console.log(arrayBuffer)
     }).then(response => response.json())
                 .then(responseJson => {
       // Create an object URL from the Blob
       console.log("\n\n ############## ");
      console.log(responseJson);
     // let SceneList = null;

      if(responseJson){
        this.setState({ dataScenes: responseJson });
     }

     
                 })        
  };

  render() {
    let dataScene = null
    const responseJsonAux = Object.assign([], this.state.dataScenes);
    dataScene =   Object.keys(responseJsonAux).map((key)=>{
      return  <li config={responseJsonAux[key]} > <h3>responseJsonAux[key]["sceneId"]</h3> </li>

    });

  return(
    <>
        <div style={{ maxWidth: '50vw', margin: 'auto', backgroundColor: 'white' }}>
        <div className="containerSubscibe">
          <button onClick={this.loadAllScene}>Load Scene List</button>
          <ul>
          {dataScene ?  dataScene : null}
          </ul>
        </div>           
        <div className="containerContact">
          <h1>Contact Us</h1>
          <p>Email: <a href="mailto:contact@elmahdia.com">contact@elmahdia.com</a></p>
          <div>
            <a className="icon-link" href="https://www.facebook.com/HasseneALAYA82" target="_blank">
              <img className="icon" src="/media/facebook-icon.png" alt="Facebook"></img>
            </a>
            <a className="icon-link" href="https://www.youtube.com/watch?v=q69Vu67q_TY" target="_blank">
              <img className="icon" src="/media/youtube-icon.png" alt="YouTube"></img>
            </a>
          </div>
        </div>
     
        </div>
    </>
  )
}

}

const Scene = (props)  => {

   //
   const [dataScene, setdataScene] = useState(null);
    let email;
   // let dataScene = null;

    const handleInputChange = (event) => {
      email = event.target.value;
    };
  
    const loadAllScene = async (event) => {
      //event.preventDefault();
    
      //fetch to config
      const formData = new FormData();
      formData.append("action", "sql"); 
      formData.append("sql_request", "SELECT DISTINCT * FROM elmahdiascene;");
    
      await fetch('/dataAction.php'+"?date="+Date.now(), {
        method: 'POST',
        cache: 'no-cache',
        headers: {
    //	   'Content-Type': 'multipart/form-data',
        },
        body: formData
    
          //  console.log(arrayBuffer)
       }).then(response => response.json())
                   .then(responseJson => {
         // Create an object URL from the Blob
         console.log("\n\n ############## ");
        console.log(responseJson);
        let SceneList = null;

        if(responseJson){
         const responseJsonAux = Object.assign([], responseJson);
         SceneList =   Object.keys(responseJsonAux).map((key)=>{
           return  <li> <h3>{responseJsonAux[key]["sceneId"]}</h3> <button  config={JSON.stringify(responseJsonAux[key])} onClick={props.areaSelectedAction}  >Load</button></li>

         });
         setdataScene(SceneList)
       }
       
                   })        
    };
  //  loadAllScene()
    return (
        <div style={{ maxWidth: '50vw', margin: 'auto', backgroundColor: 'white' }}>
        <div className="containerSubscibe">
          <button onClick={loadAllScene}>Load Scene List</button>
          <ul>
          {dataScene ?  dataScene : null}
          </ul>
        </div>           
        <div className="containerContact">
          <h1>Contact Us</h1>
          <p>Email: <a href="mailto:contact@elmahdia.com">contact@elmahdia.com</a></p>
          <div>
            <a className="icon-link" href="https://www.facebook.com/HasseneALAYA82" target="_blank">
              <img className="icon" src="/media/facebook-icon.png" alt="Facebook"></img>
            </a>
            <a className="icon-link" href="https://www.youtube.com/watch?v=q69Vu67q_TY" target="_blank">
              <img className="icon" src="/media/youtube-icon.png" alt="YouTube"></img>
            </a>
          </div>
        </div>
     
        </div>
    );
  }

function Documents() {
  return (
    <div style={{ maxWidth: '50vw', margin: 'auto', backgroundColor: 'white' }}>
    <div className="containerContact">
      <h1>Documentation</h1>
      <p></p>
      <div>
        <a className="icon-link" href="https://www.facebook.com/HasseneALAYA82" target="_blank">
          <img className="icon" src="/media/facebook-icon.png" alt="Facebook"></img>
        </a>
        <a className="icon-link" href="https://www.youtube.com/watch?v=q69Vu67q_TY" target="_blank">
          <img className="icon" src="/media/youtube-icon.png" alt="YouTube"></img>
        </a>
      </div>
    </div>
    </div>
  );
}


function QrCode() {
  const [decodedResults, setDecodedResults] = useState([]);
  const onNewScanResult = (decodedText, decodedResult) => {
      console.log("App [result]", decodedResult);
      setDecodedResults(prev => [...prev, decodedResult]);
  };  
  return (
    <div style={{ maxWidth: '50vw', margin: 'auto', backgroundColor: 'white' }}>
      <h1>HEHH</h1>
    </div>
  );
}

function Frames({ images, q = new Quaternion(), p = new Vector3() }) {
  console.log("images")
  console.log(images)

  const ref = useRef()
  const clicked = useRef()
  const [, params] = useRoute('/content/Arts.php?id=:id')
  const [, setLocation] = useLocation()
  useEffect(() => {
    clicked.current = ref.current.getObjectByName(params?.id)
    if (clicked.current) {
      clicked.current.parent.updateWorldMatrix(true, true)
      clicked.current.parent.localToWorld(p.set(0, GOLDENRATIO / 2, 1.25))
      clicked.current.parent.getWorldQuaternion(q)
    } else {
      p.set(0, 0, 5.5)
      q.identity()
    }
  })
  useFrame((state, dt) => {
    easing.damp3(state.camera.position, p, 0.4, dt)
    easing.dampQ(state.camera.quaternion, q, 0.4, dt)
  })
  return (
    <group
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
         setLocation(clicked.current === e.object ? '/' : '/content/Arts.php?id=' + e.object.name);
         console.log(e.object)
         location.reload()
        }}
      onPointerMissed={() => {  
        var artId = localStorage.getItem("artId");
        if(artId ){
          setLocation('/content/Arts.php?id=' +artId)
        }        
        //setLocation('/content/Arts.php?id=art1')
      }}> 
      {images.map((props) => <Frame key={props.url} {...props} /> /* prettier-ignore */)}
    </group>
  )
}

function Frame({ url, c = new Color(), ...props }) {
  console.log("Frame")
  console.log(props)
  console.log(url)

  if(props.type == "floor"){
    return
  }

  const image = useRef()
  const frame = useRef()
  const [, params] = useRoute('/content/Arts/:id')
  const [hovered, hover] = useState(false)
  const [rnd] = useState(() => Math.random())
  const name = props.artId;// getUuid(url)
  const isActive = params?.id === name
  useCursor(hovered)
  useFrame((state, dt) => {
    image.current.material.zoom = 2 + Math.sin(rnd * 10000 + state.clock.elapsedTime / 3) / 2
    easing.damp3(image.current.scale, [0.85 * (!isActive && hovered ? 0.85 : 1), 0.9 * (!isActive && hovered ? 0.905 : 1), 1], 0.1, dt)
    easing.dampC(frame.current.material.color, hovered ? 'orange' : 'white', 0.1, dt)
  })
  return (
    <group {...props}>
      <mesh
        name={name}
        onPointerOver={(e) => (e.stopPropagation(), hover(true))}
        onPointerOut={() => hover(false)}
        scale={[1, GOLDENRATIO, 0.05]}
        position={[0, GOLDENRATIO / 2, 0]}>
        <boxGeometry />
        <meshStandardMaterial color="#151515" metalness={0.5} roughness={0.5} envMapIntensity={2} />
        <mesh ref={frame} raycast={() => null} scale={[0.9, 0.93, 0.9]} position={[0, 0, 0.2]}>
          <boxGeometry />
          <meshBasicMaterial toneMapped={false} fog={false} />
        </mesh>
        <Image raycast={() => null} ref={image} position={[0, 0, 0.7]} url={url} />
      </mesh>
      <Text maxWidth={0.1} anchorX="left" anchorY="top" position={[0.55, GOLDENRATIO, 0]} fontSize={0.025}>
        {name.split('-').join(' ')}
      </Text>
    </group>
  )
}


const Popup = ({ isOpen, handleClose, children }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="popup"  >
      <div className="popup-inner">
        {children}
        <button onClick={handleClose}>Close</button>
      </div>
    </div>
  );
};



function Floor(props) {
  const boxRef = useRef();
  const [active, setActive] = useState(false);
  const [hover, setHover] = useState(false);

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isConfigOpen, setConfigOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
   // props.setautoRotateSpeed(0)
    //alert("HEHEHEH")
  };
 
  const closePopup = () => {
    setPopupOpen(false);
   // props.setautoRotateSpeed(0.2)

  };

  console.log("object3d:")
  console.log(props.config.object3d)
  const texture = useTexture(props.config.object3d);
  return (
    <mesh rotation={JSON.parse(props.config["rotation"])} 
    position={JSON.parse(props.config["position"])} scale={props.config["scale"]}
    onClick={() => {
      /*if(active){
        closePopup()
      }else{
        openPopup()
      }*/
      setActive(!active);
      console.log(props.config)

      //props.showInfoPoint(props.config)
      
    }}

    onDoubleClick={() => {
      if(isPopupOpen){
        closePopup()
      }else{
        openPopup()
      }
      //props.showInfoPoint(props.config)
      
    }}
    >
      <planeGeometry args={[10, 10]} />
      <meshStandardMaterial map={texture} />
      <Html>
          { hover ? <i>Voir Plus</i> : null }
         <Popup isOpen={isPopupOpen} handleClose={closePopup}>
            <ChatRoom config={{id: props.config.artId}} />
          </Popup>             
         </Html>       
    </mesh>
  );
}

//position={JSON.parse(props.config["position"])}  rotation={JSON.parse(props.config["position"])}{JSON.parse(artsPointAux[key]["rotation"])} scale={artsPointAux[key]["scale"]} />  
//}

const Art = (props)  => {
  const boxRef = useRef();
  const [active, setActive] = useState(false);
  const [hover, setHover] = useState(false);

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isConfigOpen, setConfigOpen] = useState(false);
 
  let new_positionX=props.position[0]
  let new_positionY=props.position[1]
  let new_positionZ=props.position[2]

  //alert(props.config.object3d)
//  const result = (typeof props.object3d != "undefined") ? useLoader(GLTFLoader, props.config.object3d) : null

if(props.config.type == "floor"){
  return  <Floor config={props.config}  position={JSON.parse(props.config["position"])}  rotation={JSON.parse(props.config["rotation"])} scale={props.config["scale"]} />  
}
let result = null;
if(props.config.object3d && (typeof props.config.object3d != "undefined") ){
   result =  useLoader(GLTFLoader, props.config.object3d)
}
  const openConfig = () => {
    //props.setautoRotateSpeed(0)
     setConfigOpen(true);
   };

  
  const set_update_position = (e) => {
    let statusUpdatePosition= e.target.value;
    if(statusUpdatePosition == "Update position"){
       document.addEventListener('keydown', update_position) 
       e.target.value = "Disable Update position" 
    }else{
      document.removeEventListener('keydown', update_position)  
      e.target.value = "Update position" 
    }
  };

  const save_config = async () => {
    //fetch to config
    const formData = new FormData();
    formData.append("action", "sql"); 
    formData.append("sql_request", "UPDATE elmahdiahotspot SET position = '["+new_positionX+","+new_positionY+","+new_positionZ+"]' WHERE id = "+TransitionPointId);
  
    await fetch('/dataAction.php'+"?date="+Date.now(), {
      method: 'POST',
      cache: 'no-cache',
      headers: {
  //	   'Content-Type': 'multipart/form-data',
      },
      body: formData
  
        //  console.log(arrayBuffer)
     }).then(response => response.json())
                 .then(responseJson => {
       // Create an object URL from the Blob
       console.log("\n\n ############## ");
      console.log(responseJson);
                 })        
  };

  const closeConfig = () => {
    //props.setautoRotateSpeed(0.2)
    setConfigOpen(false);
  };

  const update_position = (e) => {
    console.log(e);
    let new_position= e.key;
    console.log(new_position);
    switch(e.key) {
      case "ArrowUp":
        new_positionY = new_positionY + 1
        break;
      case "ArrowDown":
        new_positionY = new_positionY - 1
        break;
      case "ArrowLeft":
        new_positionX = new_positionX + 1
        break;
      case "ArrowRight":
        new_positionX = new_positionX - 1
        break;     
      case "b":
        new_positionZ = new_positionZ + 1
        break;     
      case "f":
        new_positionZ = new_positionZ - 1
        break;                                
      default:
        // code block
        return
    }
     boxRef.current.position.set(new_positionX, new_positionY, new_positionZ);
  };

  

  const openPopup = () => {
    setPopupOpen(true);
   // props.setautoRotateSpeed(0)
    //alert("HEHEHEH")
  };
 
  const closePopup = () => {
    setPopupOpen(false);
   // props.setautoRotateSpeed(0.2)

  };

 
  useFrame(() => {
   // boxRef.current.rotation.x += 0.02;
   if (hover) {
 //     boxRef.current.rotation.z += 0.05;

   }
  });

  return (
    <>   
    <group ref={boxRef} position={props.position}>   
      <mesh
        onClick={() => {
          /*if(active){
            closePopup()
          }else{
            openPopup()
          }*/
          setActive(!active);
          console.log(props.config)

          //props.showInfoPoint(props.config)
          
        }}

        onDoubleClick={() => {
          if(isPopupOpen){
            closePopup()
          }else{
            openPopup()
          }
          //props.showInfoPoint(props.config)
          
        }}

        onContextMenu={(e) => {
          //setPosition(e);
          openConfig()
        }}       
        onPointerOver={() => {
          setHover(true);
        }}
        onPointerOut={() => {
          setHover(false);
        }}
      >
       <Box {...props} args={[0.1, 0.1, 0.1]} >
           {result ? <primitive object={result.scene} /> : null } 
      </Box>
     
         <Html>
          { hover ? <i>Voir Plus</i> : null }
         <Popup isOpen={isPopupOpen} handleClose={closePopup}>
            <ChatRoom config={{id: props.config.artId}} />
          </Popup>   
          <Popup isOpen={isConfigOpen}  handleClose={closeConfig}>
            <input type="button"  defaultValue="Update position" onClick={set_update_position}/>
            <input type="button"  defaultValue="Save" onClick={save_config}/>
          </Popup>             
         </Html>       
      </mesh>
      
    </group>
    </>
  );
};


 /*



 */

export default class Administrateur extends Dashboard {

  constructor(props) {
      super(props);

      this.state = {
          allscenes:null,
          configScene: null,
          //[{"sceneId":"artview_0", "cubeTexture":'[   "content/artview_0/r",     "content/artview_0/l",     "content/artview_0/u",     "content/artview_0/d",     "content/artview_0/f",     "content/artview_0/b" ]', "infoPoint":[ {"id":"1", "name":"secne1", "infopoint_id":"img2info_01", "position":[-10, 10, 26.11] }, {"id":"1", "name":"secne1", "infopoint_id":"img2info_01", "position":[20, 10, 26.11] }], "transitionPoint":[ {"id":"1", "name":"secne1", "sceneId":{"sceneId":"artview_0"}, "position":[25, 1, 42.11] },  {"id":"2", "name":"secne1test", "sceneId":{"sceneId":"viewpoint_1"}, "position":[-77, 2, 18.11] } ]} ],
          showcase:null,
          arts : null,
          artconfigs:null,
          configart:{"id":"1001"},
          modeAR: false
      
      }

      let url = window.location.href;
     // alert(url)
      if(url.indexOf("?id=")  != -1){
        let artId = url.split("?id=")[1]
        console.log(artId)
        this.loadShowcase(artId)
      }else{
        this.listShowcase()
        //location.assign('/content/Arts.php?id=' +"art1")
       
      }

  }

  displayPopup= (e) => {
    this.closeNav();
    this.popupOpen(e.target.id);
  }

  popupOpen= (displayMenuAux) => {

    let openpopup = document.getElementById("popupoverlay");
   // alert(openpopup)
     openpopup.style.opacity=1;
     openpopup.style.visibility="visible";
     this.setState({ displayMenu: displayMenuAux });
  
  }
  
  popupClose= () => {
  
    let openpopup = document.getElementById("popupoverlay");
   // alert(openpopup)
     openpopup.style.opacity=0;
     openpopup.style.visibility="hidden";
  
  }

listShowcase = async() => {
  console.log("listShowcase")
  const formData = new FormData();
  formData.append("action", "sql");
  formData.append("sql_request", "SELECT DISTINCT artId,type,description,parentId,position,rotation,url FROM elmahdiaart  WHERE type = 'showcase' " );

//    await fetch('https://virtuor.net/dataAction.php'+"?date="+Date.now(), {
  await fetch('/dataAction.php'+"?date="+Date.now(), {
    method: 'POST',
    cache: 'no-cache',
    headers: {
//	   'Content-Type': 'multipart/form-data',
    },
    body: formData

      //  console.log(arrayBuffer)
   }).then(response => response.json())
               .then(async responseJson => {
     // Create an object URL from the Blob
     console.log("\n\n ######## listShowcase ###### ");
    console.log(responseJson);
    Object.keys(responseJson).map((key)=>{
      console.log(responseJson[key])
      responseJson[key]["position"] = imagesPosition[key].position; // JSON.parse(responseJson[key]["position"])
      responseJson[key]["rotation"] = imagesPosition[key].rotation; //JSON.parse(responseJson[key]["rotation"])     
     });    
    this.setState({ 
      arts: responseJson,
      artconfigs:null
    });

               });
}

backforward = async(e) => {
  e.stopPropagation();
  var artId = localStorage.getItem("artId");
  if(artId ){
    location.assign('/content/Arts.php?id=' +artId)
  }else{
    location.assign('/content/Arts.php')
  }  
}

handleClickScan= async () => {

}

openNav= async () => {

  localStorage.setItem("vrmode",0)
  document.getElementById("mySidenav").style.width = "250px";
  document.getElementById("mySidenav").style.opacity = "1";

}		
closeNav= () => {
  console.log("closeNav")
  //alert(document.getElementById("mySidenav"))
  document.getElementById("mySidenav").style.width = "0";
  document.getElementById("mySidenav").style.opacity = "0";

}


setModeposition= () => {
  this.setState({ modeAR: !this.state.modeAR });

}


handleClickAR = async (e) => {
  // Handle object selection action here
 try{
  const session = await toggleSession('immersive-ar')
  if (session) {
    e.target.innerText = 'Exit AR'
   console.log('Exit AR');
   localStorage.setItem("armode", 1);

  } else {
    e.target.innerText = 'Enter AR'
  }
}catch(e){
  alert("AR Mode Not Supported")
}
};

handleClickVR = async (e) => {
  // Handle object selection action here
  const session = await toggleSession('immersive-vr')
  if (session) {
    e.target.innerText = 'Exit VR'
   console.log('Exit VR');
   localStorage.setItem("vrmode", 1);

  } else {
    e.target.innerText = 'Enter VR'
  }
};


loadShowcase = async(artId) => {

  const formData = new FormData();
  formData.append("action", "sql");
  formData.append("sql_request", "SELECT DISTINCT * FROM elmahdiaart  WHERE artId = '"+artId+"' " );

//    await fetch('https://virtuor.net/dataAction.php'+"?date="+Date.now(), {
  await fetch('/dataAction.php'+"?date="+Date.now(), {
    method: 'POST',
    cache: 'no-cache',
    headers: {
//	   'Content-Type': 'multipart/form-data',
    },
    body: formData

      //  console.log(arrayBuffer)
   }).then(response => response.json())
               .then(async responseJson => {
     // Create an object URL from the Blob
     console.log("\n\n ############## ");
    console.log(responseJson);
    console.log(responseJson[0].type);
    //alert(JSON.stringify(responseJson));

                if(responseJson[0].type != "showcase"){
                 // alert("hjgjhg")

                 this.setState({ artconfigs: responseJson });

                 const formData = new FormData();
                 formData.append("action", "sql");
                  formData.append("sql_request", "SELECT DISTINCT * FROM elmahdiascene WHERE panorama = '"+artId+"' "  );
               
               //    await fetch('https://virtuor.net/dataAction.php'+"?date="+Date.now(), {
                 await fetch('/dataAction.php'+"?date="+Date.now(), {
                   method: 'POST',
                   cache: 'no-cache',
                   headers: {
               //	   'Content-Type': 'multipart/form-data',
                   },
                   body: formData
               
                     //  console.log(arrayBuffer)
                  }).then(response => response.json())
                              .then(async dataResult_scene => {
                    // Create an object URL from the Blob
                    console.log("\n\n scene############## ");
                   console.log(dataResult_scene);
                   if(dataResult_scene.length == 1){
                    this.setState({ configScene: dataResult_scene });
                   }

                              })

                }else{
                  localStorage.setItem("artId", artId);

                  const formData = new FormData();
                  formData.append("action", "sql");
                  formData.append("sql_request", "SELECT DISTINCT artId,type,description,parentId,position,rotation,url FROM elmahdiaart  WHERE parentId = '"+responseJson[0].artId+"' " );
                
                //    await fetch('https://virtuor.net/dataAction.php'+"?date="+Date.now(), {
                  await fetch('/dataAction.php'+"?date="+Date.now(), {
                    method: 'POST',
                    cache: 'no-cache',
                    headers: {
                //	   'Content-Type': 'multipart/form-data',
                    },
                    body: formData
                
                      //  console.log(arrayBuffer)
                   }).then(response => response.json())
                               .then(responseJson => {
                     // Create an object URL from the Blob
                     console.log("\n\n ##############HHHHHHHHHHHH ");
                    console.log(responseJson);   
                    Object.keys(responseJson).map((key)=>{
                      console.log(responseJson[key])
                      responseJson[key]["position"] = imagesPosition[key].position; // JSON.parse(responseJson[key]["position"])
                      responseJson[key]["rotation"] = imagesPosition[key].rotation; //JSON.parse(responseJson[key]["rotation"])
                      

                     });

               
                    console.log(responseJson);     
                    this.setState({ arts: responseJson });
  
                      });  
           
                }
                
               });  
 }            
 
 
 areaSelectedAction = async(e) => {
  let configAux = e.target.getAttribute("config");
  console.log("areaSelectedAction:")

  console.log(configAux)
  let config = JSON.parse(configAux)
  //alert(e.target+"   "+e.target.getAttribute("sceneid"))
  //this.loadScene(e.target.getAttribute("sceneid"))
  this.setState({
     configScene: [config],
     arts:null
   });

};

 
configSceneUpdate = async(e) => {
  e.stopPropagation();
  let attribute = e.target.getAttribute("id");
  let value = e.target.value; //getAttribute("value");
  alert(attribute+" "+value)
  this.state.configScene[0][attribute] = value
  this.setState({
    configScene: this.state.configScene,
  });  
};

sceneUpdate = async(e) => {
};
  render() {


    let configSceneUpdates = null;
      
    if(this.state.configScene && this.state.configScene[0]){
        const configSceneAux = Object.assign([], this.state.configScene[0]);
        configSceneUpdates =   Object.keys(configSceneAux).map((key)=>{
         return <>
          <label for={key} > {key}</label>
          <input type="text" id={key} value={configSceneAux[key]} onChange={this.configSceneUpdate}></input><br/>
         </>
        });
      }

    let artsPoint = null;
    if(this.state.artconfigs){
     const artsPointAux = Object.assign([], this.state.artconfigs);
     artsPoint =   Object.keys(artsPointAux).map((key)=>{
       return  <Art config={artsPointAux[key]}  position={JSON.parse(artsPointAux[key]["position"])}  rotation={JSON.parse(artsPointAux[key]["rotation"])} scale={artsPointAux[key]["scale"]} />  
     });
   }
  

   let PopupMenuDisplay = null;
   if(this.state.displayMenu == "Scene"){
   // PopupMenuDisplay = new Scene();
   }
   if(this.state.displayMenu == "Documents"){
    PopupMenuDisplay = Documents();
   }
   if(this.state.displayMenu == "Infos"){
    PopupMenuDisplay = Infos();
   }
   if(this.state.displayMenu == "QrCode"){
    PopupMenuDisplay = QrCode();
   }   

   
  return(
    <>
  {this.state.arts ? <>

    <div id="mySidenav" className="sidenav">
<div className="sidebar-header">
<a href="javascript:void(0)" onClick={this.closeNav} className="closebtn">&times;</a>

          <h3  onClick={this.closeNav}  >El Mahdia Arts (المهدية)</h3>

  </div>
  <div className="side-menu">  
  
  <a href="#" onClick={this.displayPopup} id="QrCode" ><img style={{height:"7vh"}} src="/media/scan-qrcode-svgrepo-com.svg"></img></a>


  <a href="#" onClick={this.displayPopup} id="Scene" >Scene</a>
  <a href="#"  onClick={this.displayPopup} id="Documents" >Documents</a>
  <a href="#"   onClick={this.displayPopup} id="Infos" >Infos</a>  
  <a href="/"><b style={{color:"blue",fontSize: "12px"}}>ElMahdia.com</b></a>
  <a href="#"><b style={{color:"#7386d5",fontSize: "32px"}}>.</b></a>

  </div>
</div>

<span onClick={this.openNav} style={{ boxShadow:"0 0 0 2px #0c0101, 0 0 0 4px #f5f9f5", position: "absolute", bottom: "40px", left: "5%", transform: "translateX(-50%)", padding: "12px 24px", border: "1px solid white", borderRadius: "4px", background: "rgba(0, 0, 0, 0.1)", color: "white", font: "0.8125rem sans-serif", outline: "none", zIndex: "99999", cursor: "pointer"}} >&equiv;</span>
<div id="popupoverlay" className="overlay">
		<div className="popup">
			<h2 id="popupTitle" ></h2>
			<a id="popupClose" onClick={this.popupClose}  className="close" href="#">&times;</a>
			<div id="popupContent" className="content">
          <Scene areaSelectedAction={this.areaSelectedAction}/>
			</div>
		</div>
</div>
   <Canvas dpr={[1, 1.5]} camera={{ fov: 70, position: [0, 2, 15] }}>
    <color attach="background" args={['#191920']} />
    <fog attach="fog" args={['#191920', 0, 15]} />
    <group position={[0, -0.5, 0]}>
      <Frames images={this.state.arts} />
      <mesh rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry args={[50, 50]} />
        <MeshReflectorMaterial
          blur={[300, 100]}
          resolution={2048}
          mixBlur={1}
          mixStrength={80}
          roughness={1}
          depthScale={1.2}
          minDepthThreshold={0.4}
          maxDepthThreshold={1.4}
          color="#050505"
          metalness={0.5}
        />
      </mesh>
    </group>
    <Environment preset="city" />
  </Canvas> 
  </> : <>
   <span onClick={this.backforward} style={{ boxShadow:"0 0 0 2px #0c0101, 0 0 0 4px #f5f9f5", position: "absolute", bottom: "40px", left: "5%", transform: "translateX(-50%)", padding: "12px 24px", border: "1px solid white", borderRadius: "4px", background: "rgba(0, 0, 0, 0.1)", color: "white", font: "0.8125rem sans-serif", outline: "none", zIndex: "99999", cursor: "pointer"}} >Exit</span>

  {this.state.configScene ? <>
      <Canvas>
        <XR>
          <ambientLight intensity={0.5} />
          <pointLight position={[5, 5, 5]} />
          <OrbitControls />
          <Image360    config={this.state.configScene} /> 
          {artsPoint}
 

        </XR> 
      </Canvas>  
      <div id="sceneUpdate" style={{position:"absolute",top:0}}>
        {configSceneUpdates}
        <button onClick={this.sceneUpdate}></button>
      </div>

      <VRButton style={{ boxShadow:"0 0 0 2px #0c0101, 0 0 0 4px #f5f9f5", position: "absolute", bottom: "40px", left: "50%", transform: "translateX(0%)", padding: "12px 24px", border: "1px solid white", borderRadius: "4px", background: "rgba(0, 0, 0, 0.1)", color: "white", font: "0.8125rem sans-serif", outline: "none", zIndex: "99999", cursor: "pointer"}}/>

  </> : <>
  <ARButton style={{ boxShadow:"0 0 0 2px #0c0101, 0 0 0 4px #f5f9f5", position: "absolute", bottom: "40px", left: "50%", transform: "translateX(0%)", padding: "12px 24px", border: "1px solid white", borderRadius: "4px", background: "rgba(0, 0, 0, 0.1)", color: "white", font: "0.8125rem sans-serif", outline: "none", zIndex: "99999", cursor: "pointer"}}/>
      <Canvas>
        <XR>
          <ambientLight intensity={0.5} />
          <pointLight position={[5, 5, 5]} />
          <OrbitControls autoRotate autoRotateSpeed={0.6}  />
          <Controllers />
          {artsPoint}

        </XR>
      </Canvas>  
  </>
  }
   </>
  }

   </>
  )
}

}
